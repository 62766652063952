module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fine-grade","short_name":"FG","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/logo.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dc0ace841be422fe6a8ed0dca9f7f395"},
    },{
      plugin: require('../node_modules/@twicpics/components/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"twicpics":{"domain":"https://boisewebdesign.twic.pics"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JGQB4XXDNK"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
